import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { DialogProgressIndeterminated } from '../../../../utils/DailogComponentes';
import { Player } from 'video-react';

import CardContentVideo from '../../../../utils/img/card-content-video.svg';
import IcoRemove from '../../../../utils/img/ic-excluir.svg';
import CoverIcon from '../../../../utils/img/img-cover.svg';
import { VideoModuleModal } from './VideoModuleModal';

import '../CourseLongStyles.css';

export class VideoModule extends Component {

  constructor() {
    super();
    this.state = {
      openVideoModal: false,
      showTooltip: false
    };
  }

  componentDidMount() {
    this.setState({ loading: this.props.loading.loadingCourse });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ loading: nextProps.loading.loadingCourse });
  }

  render() {

    const isVideoEdition = this.props.content.video || this.props.content.linkcontent;

    return (
      <>
        <div className="container-content-submodule">
          <div className="loading-content">
            {this.props.loading.loadingCourse &&
              this.props.loading.indexContent === this.props.content.id && (
                <DialogProgressIndeterminated />
              )}
          </div>
          <div className="header-content-submodule">
            <div className="header-title-img">
              <img src={CardContentVideo} alt="Componente de Video" />
              <div className="header-content-description">
                <p>CARD VIDEO</p>
                <p>{this.props.content.question_text}</p>
              </div>
            </div>
            <div className="removed-submodule">
              <img
                src={IcoRemove}
                alt="Remover Conteudo"
                onClick={() =>
                  this.props.loading.waitLoadingMovieImage
                    ? this.props.callbackImageMovie()
                    : this.props.removedSubModuleAndContent(
                      this.props.content.id,
                      'content',
                      this.props.index,
                      this.props.indexContent,
                      "v2"
                    )
                }
              />
            </div>
          </div>
          <div className="video-cover-container">
            {isVideoEdition ? (
              <div onMouseLeave={() => this.setState({ showTooltip: false })} style={{ width: '100%' }}>
                <div onMouseOver={() => this.setState({ showTooltip: true })}>
                  {this.props.content.kind_content === 'embeded_video_link' ? (
                    <ReactPlayer
                      controls
                      style={{ marginLeft: '2px', marginTop: '10px' }}
                      width={'100%'}
                      url={this.props.content.linkcontent}
                    />
                  ) : (
                    <Player src={this.props.content.video.url} />
                  )}
                  <div
                    className={
                      this.state.showTooltip ? 'btn-edit-image-show' : 'btn-edit-image'
                    }
                    style={{ width: '125px' }}
                  >
                    <div className="container-tooltip">
                      <label
                        onClick={() => this.setState({ openVideoModal: true })}
                        className="label-btn-imag"
                      >
                        Substituir
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="image-cover-default"
                onClick={() => this.setState({ openVideoModal: true })}
              >
                <img alt="Capa do conteudo escolhido" src={CoverIcon} />
                <p>Adicionar video</p>
              </div>
            )}
          </div>
        </div>
        <VideoModuleModal
          openVideoModal={this.state.openVideoModal}
          setOpenVideoModal={(value) => this.setState({
            openVideoModal: value
          })}
          patchMovieContent={(value, type) => this.props.patchMovieContent(
            this.props.subModule.id,
            this.props.content.id,
            value,
            type
          )}
        />
      </>
    );
  }
}

export default VideoModule;
