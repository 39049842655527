import React, { useState } from 'react';
import { Field } from 'redux-form';
import Modal from 'react-awesome-modal';
import DashboardButton from '../../../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import ToolsButton from '../../../../Tools/ToolsList/Components/ToolsButton/ToolsButton';
import { NewDropZoneCover } from '../../../../utils_form/FormComponents';

export const VideoModuleModal = (props) => {

  const { openVideoModal, setOpenVideoModal, patchMovieContent } = props;

  const [videoKind, setVideoKind] = useState('video');
  const [file, setFile] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [videoFile, setVideoFile] = useState([]);
  const [videoEmbededUrl, setVideoEmbededUrl] = useState('');
  const [uploadedVideo, setUploadedVideo] = useState([]);

  const onOptionChange = (e) => {
    setVideoKind(e.target.value);
  };

  const onClear = () => {
    setInputValue('');
    setFile(null);
    setVideoFile([]);
    setOpenVideoModal(false);
  };

  const onUploadVideo = () => {
    if (videoFile.length) {
      setUploadedVideo(videoFile);
      patchMovieContent(videoFile, videoKind);
    }

    if (inputValue !== '') {
      setVideoEmbededUrl(inputValue);
      patchMovieContent(inputValue, videoKind);
    }

    onClear();
  };

  return (
    <Modal width="550px" height="350px" visible={openVideoModal}>
      <div className="modal_container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <h4 className="modal_title">
            Selecione como você irá inserir o vídeo
          </h4>

          <form className="modal_container_select">
            <div className="modal_select">
              <input
                type="radio"
                name="kindContent"
                value="video"
                onChange={onOptionChange}
                checked={videoKind === 'video'}
              />
              <label className="modal_select_label">
                Upload de arquivo
              </label>
            </div>

            <div className="modal_select">
              <input
                type="radio"
                name="kindContent"
                value="embeded_video_link"
                onChange={onOptionChange}
                checked={videoKind === 'embeded_video_link'}
              />
              <label className="modal_select_label">
                Link do Youtube
              </label>
            </div>
          </form>
        </div>

        {videoKind === 'embeded_video_link' ? (
          <input
            className="modal_link_input"
            type="text"
            value={inputValue}
            placeholder="http://www..."
            onChange={(e) => setInputValue(e.target.value)}
          />
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="img-create">
                <div className="cover-img-publish cover-img-publish-create-post">
                  <Field
                    name="cover_form"
                    disableClick={false}
                    component={(e) => (
                      <NewDropZoneCover
                        dispatchImageError={props.dispatchImageError}
                        dispatchGifError={props.dispatchGifError}
                        videoModalCallback={setOpenVideoModal}
                        videoEmbededUrl={videoEmbededUrl}
                        isVideoUploadByModal={true}
                        openVideoModal={openVideoModal}
                        setVideoFile={setVideoFile}
                        videoFile={videoFile}
                        uploadedVideo={uploadedVideo}
                        {...e}
                      />
                    )}
                    onChange={(e) => setFile(e[0])}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="modal_container_buttons">
          <DashboardButton
            withoutMargin
            label={'Cancelar'}
            onClick={() => onClear()}
            style={{ width: '100px' }}
          />
          <ToolsButton
            disabled={!videoFile.length && inputValue === ''}
            isDisabled={!videoFile.length && inputValue === ''}
            label="Continuar"
            onClick={() => onUploadVideo()}
            style={{ width: '100px' }}
          />
        </div>
      </div>
    </Modal>
  )
}
