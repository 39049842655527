import { MuiThemeProvider } from 'material-ui/styles';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import ModuleApi from '../../../../Logicas/ModuleApi';
import * as ModuleApiAsync from '../../../../Logicas/ModuleApiAsync';
import CourseApi from '../../../../Logicas/CourseApi';
import ContentApi from '../../../../Logicas/ContentApi';
import * as ContentApiAsync from '../../../../Logicas/ContentApiAsync';

import ComponentContent from './ComponentContent';
import { required } from '../../../validation/FormValidation';
import { TextForm } from '../../../utils_form/FormComponents';
import DialogProgressCircular from '../../../utils/DialogProgressCircular';
import QuizModule from './Modules/QuizModule';
import VideoModule from './Modules/VideoModule';
import ImageModule from './Modules/ImageModule';
import TextModule from './Modules/TextModule';
import LinkModule from './Modules/LinkModule';
import FileModule from './Modules/FileModule';
import { style } from '../../../../style';

import IcoRemove from '../../../utils/img/ic-excluir.svg';

import './CourseLongStyles.css';

class CreateModules extends Component {
  constructor() {
    super();
    this.state = {};
    this.timeout = 0;
    this.requestSaveModule = this.requestSaveModule.bind(this);
    this.formatNameSubmodule = this.formatNameSubmodule.bind(this);

    this.sendContentText = this.sendContentText.bind(this);
    this.sendContentQuiz = this.sendContentQuiz.bind(this);
    this.patchTextContent = this.patchTextContent.bind(this);
    this.patchImageContent = this.patchImageContent.bind(this);
    this.patchMovieContent = this.patchMovieContent.bind(this);
    this.sendContentImageAndMovie = this.sendContentImageAndMovie.bind(this);
    this.setStateSubmodules = this.setStateSubmodules.bind(this);
    this.sendContentLink = this.sendContentLink.bind(this);
    this.patchLinkContent = this.patchLinkContent.bind(this);
    this.patchAddFileContent = this.patchAddFileContent.bind(this);
    this.patchRemoveFileContent = this.patchRemoveFileContent.bind(this);

    this.patchTextContentAnswers = this.patchTextContentAnswers.bind(this);
    this.chooseAnswersCorrect = this.chooseAnswersCorrect.bind(this);
    this.addQuizAnswer = this.addQuizAnswer.bind(this);
    this.removeQuizAnswer = this.removeQuizAnswer.bind(this);

    this.removedSubModuleAndContent = this.removedSubModuleAndContent.bind(
      this
    );
  }

  componentDidMount() {
    if (this.props.moduleActiveEdit) {
      this.setState({
        id: this.props.moduleActiveEdit.id,
        name: this.props.moduleActiveEdit.name,
        score: this.props.moduleActiveEdit.score,
        submodules: this.props.moduleActiveEdit.submodules,
        description: this.props.moduleActiveEdit.description,
        courseDetails: this.props.courseDetails
      });
    }
  }

  requestSaveModule(value) {
    if (value && value.length > 0) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.props.enableEdit();
        if (this.state.name.length > 0) {
          this.props.UpdateModule(
            this.state,
            this.props.moduleActiveEdit.id,
            this.props.courseIdActiveEdit
          );
        }
      }, 1500);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.moduleActiveEdit !== undefined) {
      this.setState({
        id: nextProps.moduleActiveEdit.id,
        name: nextProps.moduleActiveEdit.name,
        score: nextProps.moduleActiveEdit.score,
        submodules: nextProps.moduleActiveEdit.submodules,
        description: nextProps.moduleActiveEdit.description,
        courseModules: nextProps.courseDetails
      });
    }
    this.props.patchCourse();
  }

  formatNameSubmodule(value, index, idSubmodule) {
    let aux = this.state.submodules;
    aux[index].name = value;
    if (value.length <= 255) {
      this.setState({ submodules: aux });
    }
    if (value && value.length > 0) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.props.enableEdit();
        if (aux[index].name.length > 0) {
          this.props.UpdateModule(
            aux[index],
            idSubmodule,
            this.props.moduleActiveEdit.course_id
          );
        }
      }, 1500);
    }
  }

  sendContentText(submodule) {
    if (
      (submodule.contents.length > 0 &&
        submodule.contents[0].kind_content !== 'quiz') ||
      submodule.contents
    ) {
      this.props.saveContentText(
        submodule.id,
        this.props.moduleActiveEdit.course_id
      );
    }
  }

  sendContentFile(submodule) {
    if (
      (submodule.contents.length > 0 &&
        submodule.contents[0].kind_content !== 'quiz') ||
      submodule.contents
    ) {
      this.props.saveContentFile(
        submodule.id,
        this.props.moduleActiveEdit.course_id
      );
    }
  }

  patchAddFileContent(indexSubmodule, IndexContent, values) {
    this.props.loadingRequest(
      this.state.submodules[indexSubmodule].contents[IndexContent].id
    );
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.saveFile(
        values,
        this.state.submodules[indexSubmodule].id,
        this.state.submodules[indexSubmodule].contents[IndexContent].id,
        this.props.moduleActiveEdit.course_id
      );
    }, 1500);
  }

  patchRemoveFileContent(indexSubmodule, IndexContent, fileId) {
    this.props.loadingRequest(
      this.state.submodules[indexSubmodule].contents[IndexContent].id
    );
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.removeFile(
        fileId,
        this.state.submodules[indexSubmodule].id,
        this.state.submodules[indexSubmodule].contents[IndexContent].id,
        this.props.moduleActiveEdit.course_id
      );
    }, 1500);
  }

  sendContentLink(submodule) {
    if (
      (submodule.contents.length > 0 &&
        submodule.contents[0].kind_content !== 'quiz') ||
      submodule.contents
    ) {
      this.props.saveContentLink(
        submodule.id,
        this.props.moduleActiveEdit.course_id
      );
    }
  }

  patchLinkContent(indexSubmodule, IndexContent, title, linkcontent) {
    this.props.loadingRequest(
      this.state.submodules[indexSubmodule].contents[IndexContent].id
    );
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.patchContentLink(
        { title, linkcontent },
        this.state.submodules[indexSubmodule].id,
        this.state.submodules[indexSubmodule].contents[IndexContent].id,
        this.props.moduleActiveEdit.course_id
      );
    }, 1500);
  }

  patchTextContent(indexSubmodule, IndexContent) {
    this.props.loadingRequest(
      this.state.submodules[indexSubmodule].contents[IndexContent].id
    );
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.patchContentText(
        this.state.submodules[indexSubmodule].contents[IndexContent],
        this.state.submodules[indexSubmodule].id,
        this.state.submodules[indexSubmodule].contents[IndexContent].id,
        this.props.moduleActiveEdit.course_id
      );
    }, 1500);
  }

  sendContentImageAndMovie(indexSubmodule, type, submodule) {
    let aux = this.state.submodules;
    if (
      (submodule.contents.length > 0 &&
        submodule.contents[0].kind_content !== 'quiz') ||
      aux[indexSubmodule]
    ) {
      aux[indexSubmodule].contents.push({
        id: 0,
        kind_content: type
      });
      this.setState({ submodules: aux });
    }
  }

  patchImageContent(idSubmodule, idContent, values) {
    this.props.loadingRequest(idContent);
    if (values) {
      this.props.waitLoadingMovieImage(true);
      if (idContent === 0) {
        this.props.saveContentImage(
          { title_card: values[0].name, img_file: values },
          idSubmodule,
          this.props.moduleActiveEdit.course_id
        );
      } else {
        this.props.patchContentImage(
          { title_card: values[0].name, img_file: values },
          idSubmodule,
          idContent,
          this.props.moduleActiveEdit.course_id
        );
      }
    }
  }

  patchMovieContent(idSubmodule, idContent, values, type) {
    this.props.loadingRequest(idContent);
    if (values) {
      this.props.waitLoadingMovieImage(true);
      if (idContent === 0) {
        if (type === 'embeded_video_link') {
          this.props.saveContentEmbededVideoLink(
            values,
            idSubmodule,
            this.props.moduleActiveEdit.course_id,
          )
        } else {
          this.props.saveContentVideoV2(
            { title_card: values[0].name, img_file: values },
            idSubmodule,
            this.props.moduleActiveEdit.course_id,
            style.teamName.name
          );
        }
      } else {
        if (type === 'embeded_video_link') {
          this.props.patchContentEmbededVideoLink(
            values,
            idSubmodule,
            idContent,
            this.props.moduleActiveEdit.course_id,
          )
        } else {
          this.props.patchContentVideoV2(
            { title_card: values[0].name, img_file: values },
            idSubmodule,
            idContent,
            this.props.moduleActiveEdit.course_id,
            style.teamName.name
          );
        }
      }
    }
  }

  sendContentQuiz(submodule) {
    if (submodule.contents.length === 0) {
      this.props.saveContentQuiz(
        { question_text: 'Pergunta', score: 0 },
        submodule.id,
        this.props.moduleActiveEdit.course_id
      );
    }
  }

  patchTextContentAnswers(value, indexSubmodule, indexContent, indexAnswers) {
    let aux = this.state.submodules;
    if (value.length < 255) {
      if (indexAnswers !== undefined) {
        aux[indexSubmodule].contents[indexContent].answers[
          indexAnswers
        ].answer_text = value;
      } else {
        aux[indexSubmodule].contents[indexContent].question_text = value;
      }
      if (value && value.length > 0) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.props.patchContentQuiz(
            aux[indexSubmodule].contents[indexContent],
            aux[indexSubmodule].contents[indexContent].id,
            this.props.moduleActiveEdit.course_id,
            indexAnswers,
            'textField'
          );
        }, 1500);
      }

      this.setState({
        submodules: aux
      });
    }
  }

  chooseAnswersCorrect(values, indexSubmodule, indexContent, indexAnswers) {
    let aux = this.state.submodules;
    if (
      aux[indexSubmodule].contents[indexContent].answers[indexAnswers] &&
      values
    ) {
      aux[indexSubmodule].contents[indexContent].answers.map(
        (answer, index) => {
          if (index === indexAnswers) {
            answer.is_correct = values;
          } else {
            answer.is_correct = false;
          }
        }
      );
      this.props.patchContentQuiz(
        aux[indexSubmodule].contents[indexContent],
        aux[indexSubmodule].contents[indexContent].id,
        this.props.moduleActiveEdit.course_id,
        indexAnswers,
        'toggleField'
      );
    }

    this.setState({
      submodules: aux
    });
  }

  removedSubModuleAndContent(id, type, indexSubmodule, indexContent, endpointVersion = "v2") {
    if (type === 'subModule') {
      this.props.deleteModule(id, this.props.moduleActiveEdit.course_id);
    } else if (type === 'content') {
      if (id !== 0 || (!indexSubmodule && !indexContent)) {
        if (endpointVersion === "v2") {
          this.props.deleteContentV2(id, this.props.moduleActiveEdit.course_id);
        } else {
          this.props.deleteContent(id, this.props.moduleActiveEdit.course_id);
        }
      } else {
        let aux = this.state.submodules;
        aux[indexSubmodule].contents.splice(indexContent, 1);
        this.setState({
          submodules: aux
        });
      }
      this.props.waitLoadingMovieImage(false);
    }
  }

  textInputHandler(event, editor) {
    const value = editor.getData();
    return value;
  }

  setStateSubmodules(aux) {
    this.setState({ submodules: aux });
  }

  addQuizAnswer(index) {
    const allAnswers = this.state.submodules[index].contents[0].answers;
    const answersQty = allAnswers.length;
    const quizId = allAnswers.slice(-1)[0].content_id;

    this.props.addQuizAnswer(
      answersQty,
      quizId,
      this.props.initialValues.course_id,
      'Longo'
    );
  }

  removeQuizAnswer(index, answerIndex) {
    const allAnswers = this.state.submodules[index].contents[0].answers;
    const targetAnswer = allAnswers[answerIndex].id;

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.removeQuizAnswer(
        targetAnswer,
        this.props.initialValues.course_id,
        'Longo'
      );
    }, 1000);
  }

  render() {
    return (
      <MuiThemeProvider>
        <div className="body-create-modules-long">
          {this.state && (
            <div className="container-create-modules">
              <div className="title-module-preview">
                MÓDULO: {this.state.name}
              </div>
              <div className="row">
                <div className="title-module col-md-12">
                  <TextForm
                    type="text"
                    value={this.state.name}
                    validations={[required]}
                    fullWidth={true}
                    disabled={this.props.loading}
                    onChange={(event) => {
                      event.target.value.length <= 255 &&
                        this.setState(
                          { name: event.target.value },
                          this.requestSaveModule(event.target.value)
                        );
                    }}
                    floatingLabelText="Nome do módulo"
                  />
                  <p className="view-characters">
                    {this.state.name &&
                      this.state.name.length <= 255 &&
                      `${255 - this.state.name.length} caracteres restantes.`}
                  </p>
                  <div
                    className={
                      this.props.loading
                        ? 'loading loading-container'
                        : 'loading-container'
                    }
                    style={{
                      transform: 'translateY(-65px)',
                      width: '480px'
                    }}
                  ></div>
                </div>
              </div>
              <div className="row">
                <div className="description-module col-md-12">
                  <TextForm
                    type="text"
                    value={this.state.description}
                    validations={[required]}
                    fullWidth={true}
                    disabled={this.props.loading}
                    onChange={(event) => {
                      event.target.value.length <= 255 &&
                        this.setState(
                          { description: event.target.value },
                          this.requestSaveModule(event.target.value)
                        );
                    }}
                    floatingLabelText="Descrição do módulo"
                  />
                  <p className="view-characters">
                    {this.state.description &&
                      this.state.description.length <= 255 &&
                      `${255 -
                      this.state.description.length} caracteres restantes.`}
                  </p>
                  <div
                    className={
                      this.props.loading
                        ? 'loading loading-container'
                        : 'loading-container'
                    }
                    style={{
                      transform: 'translateY(-65px)',
                      width: '480px'
                    }}
                  ></div>
                </div>
              </div>
              <div className="form-details-SubModule">
                {this.state.submodules &&
                  this.state.submodules.map((SubModule, index) => {
                    return (
                      <div key={SubModule.id}>
                        <div className="label-details-form">
                          <div className="title-submodule-preview">
                            SUBMÓDULO {index + 1}: {SubModule.name}
                            <div className="removed-submodule">
                              <img
                                src={IcoRemove}
                                alt="Remover submódulo"
                                onClick={() =>
                                  this.removedSubModuleAndContent(
                                    SubModule.id,
                                    'subModule'
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="form-details-module">
                            <div className="title-submodule-field">
                              <TextForm
                                type="text"
                                value={SubModule.name}
                                validations={[required]}
                                fullWidth={true}
                                disabled={this.props.loading}
                                onChange={(event) =>
                                  this.formatNameSubmodule(
                                    event.target.value,
                                    index,
                                    SubModule.id
                                  )
                                }
                                floatingLabelText="Nome do submódulo"
                                label="Insira o nome do submódulo"
                              />
                              <p className="view-characters">
                                {SubModule.name &&
                                  SubModule.name.length <= 255 &&
                                  `${255 -
                                  SubModule.name
                                    .length} caracteres restantes.`}
                              </p>
                              <div
                                className={
                                  this.props.loading
                                    ? 'loading loading-container'
                                    : 'loading-container'
                                }
                                style={{
                                  transform: 'translateY(-65px)',
                                  width: '480px'
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="label-details-form">
                          <ComponentContent
                            contentsSubmodule={SubModule.contents}
                            sendContentText={() => {
                              this.props.progress_course_loading
                                .waitLoadingMovieImage
                                ? this.props.callbackImageMovie()
                                : this.sendContentText(SubModule);
                            }}
                            sendContentImage={() => {
                              this.props.progress_course_loading
                                .waitLoadingMovieImage
                                ? this.props.callbackImageMovie()
                                : this.sendContentImageAndMovie(
                                  index,
                                  'image',
                                  SubModule
                                );
                            }}
                            sendContentMovie={() => {
                              this.props.progress_course_loading
                                .waitLoadingMovieImage
                                ? this.props.callbackImageMovie()
                                : this.sendContentImageAndMovie(
                                  index,
                                  'video',
                                  SubModule
                                );
                            }}
                            sendContentQuiz={() =>
                              this.sendContentQuiz(SubModule)
                            }
                            sendContentLink={() =>
                              this.sendContentLink(SubModule)
                            }
                            sendContentFile={() =>
                              this.sendContentFile(SubModule)
                            }
                          />
                        </div>
                        <div className="label-details-form">
                          {SubModule.contents &&
                            SubModule.contents.map((Content, IndexContent) => {
                              switch (Content.kind_content) {
                                case 'text':
                                  return (
                                    <TextModule
                                      key={IndexContent}
                                      content={Content}
                                      loading={
                                        this.props.progress_course_loading
                                      }
                                      index={index}
                                      indexContent={IndexContent}
                                      subModule={SubModule}
                                      stateSubModules={this.state.submodules}
                                      patchTextContent={this.patchTextContent}
                                      removedSubModuleAndContent={
                                        this.removedSubModuleAndContent
                                      }
                                      textInputHandler={this.textInputHandler}
                                      setStateSubmodules={
                                        this.setStateSubmodules
                                      }
                                    />
                                  );
                                case 'image':
                                  return (
                                    <ImageModule
                                      key={IndexContent}
                                      content={Content}
                                      loading={
                                        this.props.progress_course_loading
                                      }
                                      index={index}
                                      indexContent={IndexContent}
                                      subModule={SubModule}
                                      patchImageContent={this.patchImageContent}
                                      callbackImageMovie={
                                        this.props.callbackImageMovie
                                      }
                                      removedSubModuleAndContent={
                                        this.removedSubModuleAndContent
                                      }
                                    />
                                  );
                                case 'video':
                                  return (
                                    <VideoModule
                                      key={IndexContent}
                                      content={Content}
                                      loading={
                                        this.props.progress_course_loading
                                      }
                                      index={index}
                                      indexContent={IndexContent}
                                      subModule={SubModule}
                                      patchMovieContent={this.patchMovieContent}
                                      callbackImageMovie={
                                        this.props.callbackImageMovie
                                      }
                                      removedSubModuleAndContent={
                                        this.removedSubModuleAndContent
                                      }
                                    />
                                  );
                                case 'embeded_video_link':
                                  return (
                                    <VideoModule
                                      key={IndexContent}
                                      content={Content}
                                      loading={
                                        this.props.progress_course_loading
                                      }
                                      index={index}
                                      indexContent={IndexContent}
                                      subModule={SubModule}
                                      patchMovieContent={this.patchMovieContent}
                                      callbackImageMovie={
                                        this.props.callbackImageMovie
                                      }
                                      removedSubModuleAndContent={
                                        this.removedSubModuleAndContent
                                      }
                                    />
                                  );
                                case 'quiz':
                                  return (
                                    <QuizModule
                                      key={IndexContent}
                                      content={Content}
                                      loading={
                                        this.props.progress_course_loading
                                      }
                                      index={index}
                                      indexContent={IndexContent}
                                      removedSubModuleAndContent={
                                        this.removedSubModuleAndContent
                                      }
                                      patchTextContentAnswers={
                                        this.patchTextContentAnswers
                                      }
                                      chooseAnswersCorrect={
                                        this.chooseAnswersCorrect
                                      }
                                      removeQuizAnswer={this.removeQuizAnswer}
                                      addQuizAnswer={this.addQuizAnswer}
                                    />
                                  );
                                case 'link':
                                  return (
                                    <LinkModule
                                      key={IndexContent}
                                      content={Content}
                                      loading={
                                        this.props.progress_course_loading
                                      }
                                      index={index}
                                      indexContent={IndexContent}
                                      removedSubModuleAndContent={
                                        this.removedSubModuleAndContent
                                      }
                                      patchLinkContent={
                                        this.patchLinkContent
                                      }
                                    />
                                  )
                                case 'file':
                                  return (
                                    <FileModule
                                      key={IndexContent}
                                      content={Content}
                                      loading={
                                        this.props.progress_course_loading
                                      }
                                      index={index}
                                      indexContent={IndexContent}
                                      removedSubModuleAndContent={
                                        this.removedSubModuleAndContent
                                      }
                                      patchFileContent={
                                        this.patchAddFileContent
                                      }
                                      removeFileContent={
                                        this.patchRemoveFileContent
                                      }
                                    />
                                  )
                              }
                            })}
                        </div>
                      </div>
                    );
                  })}
                <div className="label-details-SubModule">
                  <div
                    className="new-module-btn"
                    onClick={() =>
                      this.props.createSubModule(
                        this.props.moduleActiveEdit.course_id,
                        this.props.moduleActiveEdit.id,
                        this.props.moduleActiveEdit.submodules.length
                      )
                    }
                  >
                    <p>
                      <strong>+</strong>
                    </p>
                    <p>Adicionar submódulo</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <DialogProgressCircular />
      </MuiThemeProvider>
    );
  }
}

let CreateModulesContainer = reduxForm({
  form: 'createmodulo'
})(CreateModules);

const mapDispatchToProps = (dispatch) => {
  return {
    createCourse: (values) => {
      dispatch(CourseApi.createCourse(values));
    },
    UpdateModule: async (values, id, idCourse) =>
      await dispatch(ModuleApiAsync.pathModule(values, id, idCourse)),
    enableEdit: () => {
      dispatch({ type: 'DISABLE_SUBMITION' });
    },
    createSubModule: (idCourse, idSubmodule, sizeModules) =>
      dispatch(
        ModuleApi.createSubModule(
          {
            module_title: `Submódulo ${sizeModules + 1}`,
            module_score: 0,
            module_description: `Submódulo ${sizeModules + 1}`
          },
          idCourse,
          idSubmodule
        )
      ),
    saveContentText: (idSubmodule, idcourse) =>
      dispatch(
        ContentApi.saveContentText(
          {
            textcontent: '<p>Texto do conteúdo</p>',
            title: 'Titulo text'
          },
          idSubmodule,
          idcourse,
          'Longo'
        )
      ),
    patchContentText: (values, idSubmodule, idcontent, idcourse) => {
      dispatch(
        ContentApi.patchContentText(values, idSubmodule, idcontent, idcourse)
      );
    },
    saveContentImage: (values, idSubmodule, idcourse) => {
      dispatch(
        ContentApi.saveContentImage(values, idSubmodule, idcourse, 'Longo')
      );
    },
    patchContentImage: (values, idSubmodule, id_content, idcourse) => {
      dispatch(
        ContentApi.patchContentImage(
          values,
          idSubmodule,
          id_content,
          idcourse,
          'Longo'
        )
      );
    },
    saveContentVideo: (values, idSubmodule, idcourse) => {
      dispatch(
        ContentApi.saveContentVideo(values, idSubmodule, idcourse, 'Longo')
      );
    },
    saveContentVideoV2: (values, idSubmodule, idcourse, teamName) => {
      dispatch(
        ContentApi.saveContentVideoV2(values, idSubmodule, idcourse, 'Longo', teamName)
      );
    },
    saveContentEmbededVideoLink: (values, idSubmodule, idcourse) => {
      dispatch(
        ContentApi.saveContentEmbededVideoLink(values, idSubmodule, idcourse, 'Longo')
      );
    },
    patchContentEmbededVideoLink: (values, idSubmodule, id_content, idcourse) => {
      dispatch(
        ContentApi.patchContentEmbededVideoLink(values, idSubmodule, id_content, idcourse, 'Longo')
      );
    },
    patchContentVideo: (values, idSubmodule, id_content, idcourse) => {
      dispatch(
        ContentApi.patchContentVideo(values, idSubmodule, id_content, idcourse)
      );
    },
    patchContentVideoV2: (values, idSubmodule, id_content, idcourse, teamName) => {
      dispatch(
        ContentApi.patchContentVideoV2(values, idSubmodule, id_content, idcourse, teamName)
      );
    },
    saveContentQuiz: (values, idSubmodule, idcourse) => {
      dispatch(
        ContentApi.saveContentQuiz(values, idSubmodule, idcourse, 'Longo')
      );
    },
    patchContentQuiz: async (
      values,
      idContent,
      idcourse,
      indexAnswers,
      fieldKind
    ) => {
      await dispatch(
        ContentApiAsync.PatchContentQuiz(
          values,
          idContent,
          idcourse,
          'Longo',
          indexAnswers,
          fieldKind
        )
      );
    },
    saveContentFile: (idSubmodule, idcourse) =>
      dispatch(
        ContentApi.saveContentFile(
          {
            title: ''
          },
          idSubmodule,
          idcourse,
          'Longo'
        )
      ),
    saveFile: (values, idSubmodule, idcontent, idcourse) => {
      values.map(file => {
        dispatch(
          ContentApi.saveFile(file, idSubmodule, idcontent, idcourse)
        );
      })
    },
    removeFile: (fileId, idSubmodule, idcontent, idcourse) => {
      dispatch(
        ContentApi.removeFile(fileId, idSubmodule, idcontent, idcourse)
      );
    },
    saveContentLink: (idSubmodule, idcourse) =>
      dispatch(
        ContentApi.saveContentLink(
          {
            title: '',
            linkcontent: ''
          },
          idSubmodule,
          idcourse,
          'Longo'
        )
      ),
    patchContentLink: (values, idSubmodule, idcontent, idcourse) => {
      dispatch(
        ContentApi.pathContentLink(values, idSubmodule, idcontent, idcourse)
      );
    },
    addQuizAnswer: (answersQty, quizId, idCourse, typeCourse) => {
      dispatch(
        ContentApi.addQuizAnswer(answersQty, quizId, idCourse, typeCourse)
      );
    },
    removeQuizAnswer: (targetAnswer, quizId, typeCourse) => {
      dispatch(ContentApi.removeQuizAnswer(targetAnswer, quizId, typeCourse));
    },
    deleteModule: (idSubmodule, idCourse) => {
      dispatch(ModuleApi.deleteModule(idSubmodule, idCourse, 'long'));
    },
    deleteContent: (idContent, idCourse) => {
      dispatch(ContentApi.deleteContent(idContent, idCourse, 'long'));
    },
    deleteContentV2: (idContent, idCourse) => {
      dispatch(ContentApi.deleteContentV2(idContent, idCourse, 'long'));
    },
    patchCourse: () => {
      dispatch({ type: 'PATCH_COURSE', patchCourse: 1 });
    },
    loadingRequest: (idcontent) => {
      dispatch({
        type: 'SHOW_PROGRESS_COURSE_INDETERMINATE',
        indexContent: idcontent
      });
    },
    callbackImageMovie: () => {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: 'Por favor aguarde.'
        }
      });
    },
    waitLoadingMovieImage: (value) => {
      dispatch({
        type: 'WAIT_LOADING_MOVIE_IMAGE',
        waitLoadingMovieImage: value
      });
    }
  };
};

CreateModulesContainer = connect(
  (state, props) => ({
    module: state.module,
    initialValues: props.moduleActiveEdit,
    courseDetails: props.courseDetails,
    patchCourseState: state.course.patchCourse,
    progress_course_loading: state.progress_course_loading,
    loading: state.progress_indeterminate
  }),
  mapDispatchToProps
)(CreateModulesContainer);

export default CreateModulesContainer;
