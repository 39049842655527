import PubSub from 'pubsub-js';
import Axios from 'axios';
import API, {
  API_GATEWAY_UPLOAD_VIDEO,
  API_RANDOM_UPLOAD_VIDEO,
  API_V2
} from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import errorHandler from '../componentes/utils/errorHandler';
import ModuleApi from '../Logicas/ModuleApi';
import { style } from '../style';
import CourseApi from './CourseApi';
import { doesContainHttp } from '../componentes/validation/FormValidation';
import { getVideoCover } from '../componentes/utils/getVideoCover';
import { addImageProcess } from '../componentes/utils/addImageProcess';

const isClientCipa = style.teamName.name === 'Cipa';
export default class ContentApi {
  static editContentForm(content) {
    return (dispatch) => {
      if (content !== undefined) {
        if (content.kindcontent === 'quiz') {
          content.answer1 = content.answer[0].answerstext;
          content.answer2 = content.answer[1].answerstext;
          content.answer3 = content.answer[2].answerstext;
          content.answer4 = content.answer[3].answerstext;

          content.answer1_correct = content.answer[0].iscorrect;
          content.answer2_correct = content.answer[1].iscorrect;
          content.answer3_correct = content.answer[2].iscorrect;
          content.answer4_correct = content.answer[3].iscorrect;
        }
        if (content !== undefined) {
          dispatch({
            type: 'EDIT_CONTENT',
            content: content
          });
        }
      }
    };
  }

  static saveContentText(values, idmodule, idcourse, typeCourse) {
    return (dispatch) => {
      var formData = new FormData();
      formData.append('score', values.score);
      formData.append('textcontent', values.textcontent);
      formData.append('module_course_id', idmodule);
      formData.append('kindcontent', 'text');
      formData.append('title', values.title);
      API.post('contents', formData, Config.headers())
        .then(
          function (response) {
            if (typeCourse === 'Longo') {
              dispatch(CourseApi.editCourseV2(idcourse));
            } else {
              dispatch(CourseApi.editCourse(idcourse));
            }
            dispatch({ type: 'NO_SUBMITTING' });
            PubSub.publish('remove-new-content', true);
            PubSub.publish('clear_new_elements', true);
            setTimeout(() => {
              PubSub.publish('move_scroll', true);
            }, 1000);
          }.bind(this)
        )
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static isImg(file) {
    return (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === '.jpg' ||
      file.type === '.png' ||
      file.type === '.jpeg' ||
      file.type === 'image/gif'
    );
  }

  static isGif(file) {
    return file.type === 'image/gif';
  }

  static saveContentImage(values, idmodule, idcourse, typeCourse) {
    return async (dispatch) => {
      if (values.img_file !== undefined) {
        try {
          if (
            !this.isImg(values.img_file[0]) &&
            !this.isGif(values.img_file[0])
          )
            throw new Error('Tipo de arquivo não suportado.');

          if (this.isImg(values.img_file[0])) {
            const i = await addImageProcess(
              URL.createObjectURL(values.img_file[0])
            );
            if (i.height > 2000 || i.width > 2000)
              throw new Error('Não foi possível carregar a imagem');
          }

          if (
            this.isGif(values.img_file[0]) &&
            values.img_file[0].size / (1024 * 1024).toFixed(2) > 20
          )
            throw new Error(
              'Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.'
            );

          var formData = new FormData();
          formData.append('score', 0);
          formData.append('textcontent', '');
          formData.append('module_course_id', idmodule);
          formData.append('kindcontent', 'image');
          formData.append('title', values.title_card);
          formData.append('image', values.img_file[0]);
          API.post('contents', formData, Config.headers())
            .then(
              function (response) {
                if (typeCourse === 'Longo') {
                  dispatch(CourseApi.editCourseV2(idcourse));
                } else {
                  dispatch(CourseApi.editCourse(idcourse));
                }
                dispatch({ type: 'NO_SUBMITTING_CONTENT' });
                PubSub.publish('remove-new-content', true);
                PubSub.publish('clear_new_elements', true);
                setTimeout(() => {
                  PubSub.publish('move_scroll', true);
                }, 1000);
                dispatch({ type: 'NO_SUBMITTING' });
                dispatch({
                  type: 'WAIT_LOADING_MOVIE_IMAGE',
                  waitLoadingMovieImage: false
                });
              }.bind(this)
            )
            .catch((err) => {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
        } catch (error) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(error),
              submessage:
                error.message === 'Não foi possível carregar a imagem'
                  ? 'O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.'
                  : ''
            }
          });
          dispatch({ type: 'HIDE_PROGRESS_COURSE_INDETERMINATE' });
          dispatch({
            type: 'WAIT_LOADING_MOVIE_IMAGE',
            waitLoadingMovieImage: false
          });
        }
      } else {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: 'Imagem é obrigatória'
          }
        });
      }
    };
  }

  static patchContentImage(
    values,
    id_module,
    id_content,
    idcourse,
    typeCourse
  ) {
    return async (dispatch) => {
      if (values.img_file !== undefined) {
        try {
          if (
            !this.isImg(values.img_file[0]) &&
            !this.isGif(values.img_file[0])
          )
            throw new Error('Tipo de arquivo não suportado.');

          if (this.isImg(values.img_file[0])) {
            const i = await addImageProcess(
              URL.createObjectURL(values.img_file[0])
            );
            if (i.height > 2000 || i.width > 2000)
              throw new Error('Não foi possível carregar a imagem');
          }

          if (
            this.isGif(values.img_file[0]) &&
            values.img_file[0].size / (1024 * 1024).toFixed(2) > 20
          )
            throw new Error(
              'Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.'
            );

          var formData = new FormData();
          formData.append('score', 0);
          formData.append('textcontent', '');
          formData.append('kindcontent', 'image');
          formData.append('title', values.title_card);
          formData.append('image', values.img_file[0]);
          API.patch('contents/' + id_content, formData, Config.headers())
            .then(
              function (response) {
                dispatch({ type: 'NO_SUBMITTING_CONTENT' });
                if (typeCourse === 'Longo') {
                  dispatch(CourseApi.editCourseV2(idcourse));
                } else {
                  dispatch(CourseApi.editCourse(idcourse));
                }
                PubSub.publish('remove-new-content', true);
                dispatch({ type: 'NO_SUBMITTING' });
                dispatch({
                  type: 'WAIT_LOADING_MOVIE_IMAGE',
                  waitLoadingMovieImage: false
                });
              }.bind(this)
            )
            .catch((err) => {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
              dispatch({
                type: 'WAIT_LOADING_MOVIE_IMAGE',
                waitLoadingMovieImage: false
              });
            });
        } catch (error) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(error),
              submessage:
                error.message === 'Não foi possível carregar a imagem'
                  ? 'O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.'
                  : ''
            }
          });
          dispatch({ type: 'HIDE_PROGRESS_COURSE_INDETERMINATE' });
          dispatch({
            type: 'WAIT_LOADING_MOVIE_IMAGE',
            waitLoadingMovieImage: false
          });
        }
      } else {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: 'Imagem é obrigatória'
          }
        });
      }
    };
  }

  static patchContentVideo(values, id_module, id_content, idcourse) {
    return (dispatch) => {
      if (values.img_file !== undefined) {
        var formData = new FormData();
        formData.append('score', 0);
        formData.append('textcontent', '');
        formData.append('module_course_id', id_module);
        formData.append('kindcontent', 'video');
        formData.append('title', values.title_card);
        formData.append('video', values.img_file[0]);
        API.patch('contents/' + id_content, formData, Config.headers())
          .then(
            function (response) {
              if (idcourse) {
                dispatch(CourseApi.editCourseV2(idcourse));
              } else {
                dispatch(ModuleApi.getModuleEdit(id_module));
              }
              PubSub.publish('remove-new-content', true);
              dispatch({ type: 'NO_SUBMITTING_CONTENT' });
              dispatch({ type: 'NO_SUBMITTING' });
              dispatch({
                type: 'WAIT_LOADING_MOVIE_IMAGE',
                waitLoadingMovieImage: false
              });
            }.bind(this)
          )
          .catch((err) => {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
            dispatch({
              type: 'WAIT_LOADING_MOVIE_IMAGE',
              waitLoadingMovieImage: false
            });
          });
      } else {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: 'Imagem é obrigatória'
          }
        });
      }
    };
  }

  static patchContentVideoV2(
    values,
    id_module,
    id_content,
    idcourse,
    teamName
  ) {
    return async (dispatch) => {
      if (values.img_file !== undefined) {
        const f = values.img_file[0];
        if (
          (isClientCipa && f.size / (1024 * 1024).toFixed(2) > 500) ||
          (!isClientCipa && f.size / (1024 * 1024).toFixed(2) > 200)
        ) {
          // size validation --> 200mb || 500mb
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: `Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo com no máximo ${isClientCipa ? '500mb' : '200mb'
                }.`
            }
          });
          dispatch({ type: 'HIDE_PROGRESS_COURSE_INDETERMINATE' });
          dispatch({
            type: 'WAIT_LOADING_MOVIE_IMAGE',
            waitLoadingMovieImage: false
          });
        } else if (f.type !== 'video/mp4') {
          // type validation --> .mp4
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message:
                'Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo no formato .mp4.'
            }
          });
          dispatch({ type: 'HIDE_PROGRESS_COURSE_INDETERMINATE' });
          dispatch({
            type: 'WAIT_LOADING_MOVIE_IMAGE',
            waitLoadingMovieImage: false
          });
        } else {
          var CONFI = {
            headers: {
              'content-type': `${f.type}`,
              'content-length': `${f.size}`
            }
          };
          CONFI.onUploadProgress = (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            dispatch({
              type: 'START_LOAD',
              percent: percentCompleted,
              visible: true
            });
          };

          const cover = await getVideoCover(f, 0.1);

          var CONFI_IMAGE = {
            headers: {
              'content-type': `${cover.type}`,
              'content-length': `${cover.size}`
            }
          };

          // const divisor = f.name.split(".");

          const response = await fetch(
            `https://aheypgto3c.execute-api.us-east-1.amazonaws.com/default/getPresignedURL-1?team=${teamName}`,
            // `https://pfi6nr3akgr76vbbo2lyu273ui0qnpmf.lambda-url.us-east-1.on.aws?team=${teamName}&contentType=${f.type}&typeForKey=${divisor[divisor.length - 1]}`,
            {
              method: 'GET'
            }
          ).then((response) => {
            return response.json();
          });

          const video_filename = response.filename;

          const result = await API_RANDOM_UPLOAD_VIDEO.put(
            response.url,
            f,
            CONFI
          );
          const result_upload_image = await API_RANDOM_UPLOAD_VIDEO.put(
            response.url_image,
            cover,
            CONFI_IMAGE
          );

          var formData = new FormData();
          formData.append('score', 0);
          formData.append('textcontent', '');
          formData.append('module_course_id', id_module);
          formData.append('kindcontent', 'video');
          formData.append('title', '');
          formData.append('video_filename', video_filename);

          if (result.status === 200 && result_upload_image.status === 200) {
            Axios.patch(
              `${style.url.urlClient}api/v2/contents/` + id_content,
              formData,
              Config.headers()
            )
              .then(
                function (response) {
                  if (idcourse) {
                    dispatch(CourseApi.editCourseV2(idcourse));
                  } else {
                    dispatch(ModuleApi.getModuleEdit(id_module));
                  }
                  PubSub.publish('remove-new-content', true);
                  dispatch({ type: 'NO_SUBMITTING_CONTENT' });
                  dispatch({ type: 'NO_SUBMITTING' });
                  dispatch({ type: 'CLOSE_ALERT', alert: { visible: false } });
                  dispatch({ type: 'END_LOAD' });
                  dispatch({
                    type: 'WAIT_LOADING_MOVIE_IMAGE',
                    waitLoadingMovieImage: false
                  });
                }.bind(this)
              )
              .catch((err) => {
                dispatch({
                  type: 'OPEN_ERROR',
                  alert: {
                    visible: true,
                    message: errorHandler(err)
                  }
                });
                dispatch({
                  type: 'WAIT_LOADING_MOVIE_IMAGE',
                  waitLoadingMovieImage: false
                });
              });
          } else {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: 'Houve um erro no upload do vídeo'
              }
            });
          }
        }
      } else {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: 'Imagem é obrigatória'
          }
        });
      }
    };
  }

  static saveContentVideo(values, idmodule, idcourse, typeCourse) {
    return (dispatch) => {
      if (values.img_file !== undefined) {
        dispatch({
          type: 'SHOW_STATUS',
          enablesubmit: false
        });
        var CONFI = Config.headers();
        CONFI.onUploadProgress = (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch({
            type: 'START_LOAD',
            percent: percentCompleted,
            visible: true
          });
        };
        var formData = new FormData();
        formData.append('score', 0);
        formData.append('textcontent', '');
        formData.append('module_course_id', idmodule);
        formData.append('kindcontent', 'video');
        formData.append('title', values.title_card);
        formData.append('video', values.img_file[0]);
        API.post('contents', formData, CONFI)
          .then(
            function (response) {
              if (typeCourse === 'Longo') {
                dispatch(CourseApi.editCourseV2(idcourse));
              } else {
                dispatch(CourseApi.editCourse(idcourse));
              }
              dispatch({ type: 'CLOSE_ALERT', alert: { visible: false } });
              dispatch({ type: 'NO_SUBMITTING' });
              dispatch({ type: 'END_LOAD' });
              dispatch({
                type: 'WAIT_LOADING_MOVIE_IMAGE',
                waitLoadingMovieImage: false
              });
              PubSub.publish('remove-new-content', true);
              PubSub.publish('clear_new_elements', true);
              setTimeout(() => {
                PubSub.publish('move_scroll', true);
              }, 1000);
            }.bind(this)
          )
          .catch((err) => {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
            dispatch({ type: 'END_LOAD' });
            dispatch({
              type: 'WAIT_LOADING_MOVIE_IMAGE',
              waitLoadingMovieImage: false
            });
          });
      } else {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: 'Video é obrigatório'
          }
        });
      }
    };
  }

  static saveContentVideoV2(values, idmodule, idcourse, typeCourse, teamName) {
    return async (dispatch) => {
      if (values.img_file !== undefined) {
        dispatch({
          type: 'SHOW_STATUS',
          enablesubmit: false
        });

        const f = values.img_file[0];

        if (
          (isClientCipa && f.size / (1024 * 1024).toFixed(2) > 500) ||
          (!isClientCipa && f.size / (1024 * 1024).toFixed(2) > 200)
        ) {
          //size validation --> 200mb || 500mb
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: `Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo com no máximo ${isClientCipa ? '500mb' : '200mb'
                }.`
            }
          });
          dispatch({ type: 'HIDE_PROGRESS_COURSE_INDETERMINATE' });
          dispatch({
            type: 'WAIT_LOADING_MOVIE_IMAGE',
            waitLoadingMovieImage: false
          });
        } else if (f.type !== 'video/mp4') {
          // type validation --> .mp4
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message:
                'Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo no formato .mp4.'
            }
          });
          dispatch({ type: 'HIDE_PROGRESS_COURSE_INDETERMINATE' });
          dispatch({
            type: 'WAIT_LOADING_MOVIE_IMAGE',
            waitLoadingMovieImage: false
          });
        } else {
          var CONFI = {
            headers: {
              'content-type': `${f.type}`,
              'content-length': `${f.size}`
            }
          };
          CONFI.onUploadProgress = (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            dispatch({
              type: 'START_LOAD',
              percent: percentCompleted,
              visible: true
            });
          };

          const cover = await getVideoCover(f, 0.1);

          var CONFI_IMAGE = {
            headers: {
              'content-type': `${cover.type}`,
              'content-length': `${cover.size}`
            }
          };

          // const divisor = f.name.split(".");

          const response = await fetch(
            `https://aheypgto3c.execute-api.us-east-1.amazonaws.com/default/getPresignedURL-1?team=${teamName}`,
            // `https://pfi6nr3akgr76vbbo2lyu273ui0qnpmf.lambda-url.us-east-1.on.aws?team=${teamName}&contentType=${f.type}&typeForKey=${divisor[divisor.length - 1]}`,
            {
              method: 'GET'
            }
          ).then((response) => {
            return response.json();
          });

          let video_filename = response.filename;

          const result = await API_RANDOM_UPLOAD_VIDEO.put(
            response.url,
            f,
            CONFI
          );
          const result_upload_image = await API_RANDOM_UPLOAD_VIDEO.put(
            response.url_image,
            cover,
            CONFI_IMAGE
          );

          var formData = new FormData();
          formData.append('score', 0);
          formData.append('textcontent', '');
          formData.append('module_course_id', idmodule);
          formData.append('kindcontent', 'video');
          formData.append('title', '');
          formData.append('video_filename', video_filename);

          if (result.status === 200 && result_upload_image.status === 200) {
            Axios.post(
              `${style.url.urlClient}api/v2/contents`,
              formData,
              Config.headers()
            )
              .then(
                function (response) {
                  if (typeCourse === 'Longo') {
                    dispatch(CourseApi.editCourseV2(idcourse));
                  } else {
                    dispatch(CourseApi.editCourse(idcourse));
                  }
                  dispatch({ type: 'CLOSE_ALERT', alert: { visible: false } });
                  dispatch({ type: 'NO_SUBMITTING' });
                  dispatch({ type: 'END_LOAD' });
                  dispatch({
                    type: 'WAIT_LOADING_MOVIE_IMAGE',
                    waitLoadingMovieImage: false
                  });
                  PubSub.publish('remove-new-content', true);
                  PubSub.publish('clear_new_elements', true);
                  setTimeout(() => {
                    PubSub.publish('move_scroll', true);
                  }, 1000);
                }.bind(this)
              )
              .catch((err) => {
                dispatch({
                  type: 'OPEN_ERROR',
                  alert: {
                    visible: true,
                    message: errorHandler(err)
                  }
                });
                dispatch({ type: 'END_LOAD' });
                dispatch({
                  type: 'WAIT_LOADING_MOVIE_IMAGE',
                  waitLoadingMovieImage: false
                });
              });
          } else {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: 'Houve um erro no upload do vídeo'
              }
            });
          }
        }
      } else {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: 'Video é obrigatório'
          }
        });
      }
    };
  }

  static saveContentEmbededVideoLink(link, idmodule, idcourse, typeCourse) {
    return (dispatch) => {
      if (link !== undefined) {
        dispatch({
          type: 'SHOW_STATUS',
          enablesubmit: false
        });
        var CONFI = Config.headers();
        CONFI.onUploadProgress = (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch({
            type: 'START_LOAD',
            percent: percentCompleted,
            visible: true
          });
        };
        var formData = new FormData();
        formData.append('score', 0);
        formData.append('textcontent', '');
        formData.append('module_course_id', idmodule);
        formData.append('kindcontent', 'embeded_video_link');
        formData.append('title', '');
        formData.append('linkcontent', link);
        API_V2.post('contents', formData, CONFI)
          .then(
            function (response) {
              if (typeCourse === 'Longo') {
                dispatch(CourseApi.editCourseV2(idcourse));
              } else {
                dispatch(CourseApi.editCourse(idcourse));
              }
              dispatch({ type: 'CLOSE_ALERT', alert: { visible: false } });
              dispatch({ type: 'NO_SUBMITTING' });
              dispatch({ type: 'END_LOAD' });
              dispatch({
                type: 'WAIT_LOADING_MOVIE_IMAGE',
                waitLoadingMovieImage: false
              });
              PubSub.publish('remove-new-content', true);
              PubSub.publish('clear_new_elements', true);
              setTimeout(() => {
                PubSub.publish('move_scroll', true);
              }, 1000);
            }.bind(this)
          )
          .catch((err) => {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
            dispatch({ type: 'END_LOAD' });
            dispatch({
              type: 'WAIT_LOADING_MOVIE_IMAGE',
              waitLoadingMovieImage: false
            });
          });
      } else {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: 'O link do vídeo é obrigatório'
          }
        });
      }
    };
  }

  static patchContentEmbededVideoLink(link, id_module, id_content, idcourse) {
    return (dispatch) => {
      if (link !== undefined) {
        var formData = new FormData();
        formData.append('score', 0);
        formData.append('textcontent', '');
        formData.append('module_course_id', id_module);
        formData.append('kindcontent', 'embeded_video_link');
        formData.append('title', '');
        formData.append('linkcontent', link);
        API_V2.patch('contents/' + id_content, formData, Config.headers())
          .then(
            function (response) {
              if (idcourse) {
                dispatch(CourseApi.editCourseV2(idcourse));
              } else {
                dispatch(ModuleApi.getModuleEdit(id_module));
              }
              PubSub.publish('remove-new-content', true);
              dispatch({ type: 'NO_SUBMITTING_CONTENT' });
              dispatch({ type: 'NO_SUBMITTING' });
              dispatch({
                type: 'WAIT_LOADING_MOVIE_IMAGE',
                waitLoadingMovieImage: false
              });
            }.bind(this)
          )
          .catch((err) => {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
            dispatch({
              type: 'WAIT_LOADING_MOVIE_IMAGE',
              waitLoadingMovieImage: false
            });
          });
      } else {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: 'O link do vídeo é obrigatório'
          }
        });
      }
    };
  }

  static patchContentText(values, idmodule, idcontent, idcourse) {
    return (dispatch) => {
      var formData = new FormData();
      formData.append('score', values.score);
      formData.append('textcontent', values.text_content);
      formData.append('kindcontent', 'text');
      formData.append('title', values.title);
      API.patch('contents/' + idcontent, formData, Config.headers())
        .then(
          function (response) {
            if (idcourse) {
              dispatch(CourseApi.editCourseV2(idcourse));
            } else {
              dispatch(ModuleApi.getModuleEdit(idmodule));
            }
            dispatch({ type: 'NO_SUBMITTING' });
          }.bind(this)
        )
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
          dispatch(CourseApi.editCourseV2(idcourse));
          dispatch({
            type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
            indexContent: 0
          });
        });
    };
  }

  static deleteContent(idcontent, idcourse, typeCourse) {
    return (dispatch) => {
      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete('contents/' + idcontent, Config.headers())
        .then(
          function (response) {
            dispatch({
              type: 'CLOSE_ALERT',
              alert: {
                visible: false
              }
            });
            if (typeCourse === 'long') {
              dispatch(CourseApi.editCourseV2(idcourse));
            } else {
              dispatch(CourseApi.editCourse(idcourse));
            }
            PubSub.publish('remove-new-content', true);
            dispatch({ type: 'NO_SUBMITTING' });
          }.bind(this)
        )
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static deleteContentV2(idcontent, idcourse, typeCourse) {
    return (dispatch) => {
      let API_CLIENT = style.url.urlClient;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = style.url.urlClientYouseed;
      }

      Axios.delete(
        `${API_CLIENT}api/v2/contents/` + idcontent,
        Config.headers()
      )
        .then(
          function (response) {
            dispatch({
              type: 'CLOSE_ALERT',
              alert: {
                visible: false
              }
            });
            if (typeCourse === 'long') {
              dispatch(CourseApi.editCourseV2(idcourse));
            } else {
              dispatch(CourseApi.editCourse(idcourse));
            }
            PubSub.publish('remove-new-content', true);
            dispatch({ type: 'NO_SUBMITTING' });
          }.bind(this)
        )
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static saveContentQuiz(values, idmodule, idcourse, typeCourse) {
    return (dispatch) => {
      var formData = new FormData();
      formData.append('score', values.score);
      formData.append('kindcontent', 'quiz');
      formData.append('module_course_id', idmodule);
      formData.append('questiontext', values.question_text);
      formData.append('title', 'Quiz');
      API.post('contents', formData, Config.headers())
        .then(
          function (response) {
            dispatch(
              this.saveanswer(
                [
                  {
                    content_id: response.data.id,
                    answerstext: 'Alternativa 1',
                    iscorrect: true
                  },
                  {
                    content_id: response.data.id,
                    answerstext: 'Alternativa 2',
                    iscorrect: false
                  },
                  {
                    content_id: response.data.id,
                    answerstext: 'Alternativa 3',
                    iscorrect: false
                  },
                  {
                    content_id: response.data.id,
                    answerstext: 'Alternativa 4',
                    iscorrect: false
                  }
                ],
                response.data.id,
                idcourse,
                typeCourse
              )
            );
            dispatch({ type: 'NO_SUBMITTING' });
          }.bind(this)
        )
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static saveContentFile(values, idmodule, idcourse, typeCourse) {
    return (dispatch) => {
      var formData = new FormData();
      formData.append('score', values.score);
      formData.append('module_course_id', idmodule);
      formData.append('kindcontent', 'file');
      formData.append('title', values.title);
      API_V2.post('contents', formData, Config.headers())
        .then(
          function (response) {
            if (typeCourse === 'Longo') {
              dispatch(CourseApi.editCourseV2(idcourse));
            } else {
              dispatch(CourseApi.editCourse(idcourse));
            }
            dispatch({ type: 'NO_SUBMITTING' });
            PubSub.publish('remove-new-content', true);
            PubSub.publish('clear_new_elements', true);
            setTimeout(() => {
              PubSub.publish('move_scroll', true);
            }, 1000);
          }.bind(this)
        )
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static saveFile(value, idmodule, idcontent, idcourse) {
    return (dispatch) => {
      var formData = new FormData();
      formData.append('name_file', value.name);
      formData.append('format_file', value.type);
      formData.append('resource_id', idcontent);
      formData.append('file', value);
      API_V2.post(
        'contents/' + idcontent + '/content_files',
        formData,
        Config.headers()
      )
        .then(
          function (response) {
            if (idcourse) {
              dispatch(CourseApi.editCourseV2(idcourse));
            } else {
              dispatch(ModuleApi.getModuleEdit(idmodule));
            }
            dispatch({ type: 'NO_SUBMITTING' });
          }.bind(this)
        )
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
          dispatch(CourseApi.editCourseV2(idcourse));
          dispatch({
            type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
            indexContent: 0
          });
        });
    };
  }

  static removeFile(idfile, idmodule, idcontent, idcourse) {
    return (dispatch) => {
      API_V2.delete(
        'contents/' + idcontent + '/content_files/' + idfile,
        Config.headers()
      )
        .then(
          function (response) {
            if (idcourse) {
              dispatch(CourseApi.editCourseV2(idcourse));
            } else {
              dispatch(ModuleApi.getModuleEdit(idmodule));
            }
            dispatch({ type: 'NO_SUBMITTING' });
          }.bind(this)
        )
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
          dispatch(CourseApi.editCourseV2(idcourse));
          dispatch({
            type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
            indexContent: 0
          });
        });
    };
  }

  static saveContentLink(values, idmodule, idcourse, typeCourse) {
    return (dispatch) => {
      var formData = new FormData();
      formData.append('score', values.score);
      formData.append('linkcontent ', values.linkcontent);
      formData.append('module_course_id', idmodule);
      formData.append('kindcontent', 'link');
      formData.append('title', values.title);
      API.post('contents', formData, Config.headers())
        .then(
          function (response) {
            if (typeCourse === 'Longo') {
              dispatch(CourseApi.editCourseV2(idcourse));
            } else {
              dispatch(CourseApi.editCourse(idcourse));
            }
            dispatch({ type: 'NO_SUBMITTING' });
            PubSub.publish('remove-new-content', true);
            PubSub.publish('clear_new_elements', true);
            setTimeout(() => {
              PubSub.publish('move_scroll', true);
            }, 1000);
          }.bind(this)
        )
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static pathContentLink(values, idmodule, idcontent, idcourse) {
    return (dispatch) => {
      var formData = new FormData();
      formData.append('score', values.score);
      if (!doesContainHttp(values.linkcontent)) {
        values.linkcontent = `https://${values.linkcontent}`;
      }
      formData.append('linkcontent', values.linkcontent);
      formData.append('kindcontent', 'link');
      formData.append('title', values.title);
      API.patch('contents/' + idcontent, formData, Config.headers())
        .then(
          function (response) {
            if (idcourse) {
              dispatch(CourseApi.editCourseV2(idcourse));
            } else {
              dispatch(ModuleApi.getModuleEdit(idmodule));
            }
            dispatch({ type: 'NO_SUBMITTING' });
          }.bind(this)
        )
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
          dispatch(CourseApi.editCourseV2(idcourse));
          dispatch({
            type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
            indexContent: 0
          });
        });
    };
  }

  static isEmptyAnswer(values) {
    let quizAnswer = {
      isEmpty: false
    };
    if (values !== undefined) {
      const { answers } = values;
      if (answers !== undefined) {
        answers.forEach((value) => {
          if (value.answer_text === '') {
            quizAnswer.isEmpty = true;
          }
        });
      } else {
        values.forEach((value) => {
          if (value.answerstext === '') {
            quizAnswer.isEmpty = true;
          }
        });
      }
    }
    return quizAnswer;
  }

  static PatchContentQuiz(
    values,
    idcontent,
    idcourse,
    typeCourse,
    indexAnswers
  ) {
    return async (dispatch) => {
      const quizAnswer = await this.isEmptyAnswer(values);
      if (quizAnswer.isEmpty) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: 'Campo de resposta não pode ficar em branco.'
          }
        });
        dispatch({
          type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
          indexContent: 0
        });
        return;
      }

      const { answers } = values;
      const updatedAnswers = answers.map((answer) => ({
        id: answer.id,
        answerstext: answer.answer_text,
        iscorrect: answer.is_correct,
        content_id: answer.content_id,
        chosen: answer.chosen
      }));

      var formData = new FormData();
      formData.append('questiontext', values.question_text);
      formData.append('score', values.score);
      API.patch('contents/' + idcontent, formData, Config.headers())
        .then(
          function (response) {
            dispatch(
              this.updatedAnswers(
                updatedAnswers,
                idcontent,
                idcourse,
                typeCourse,
                indexAnswers
              )
            );
            dispatch({ type: 'NO_SUBMITTING' });
          }.bind(this)
        )
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
          dispatch(CourseApi.editCourseV2(idcourse));
        });
    };
  }

  static addQuizAnswer(answersQty, quizId, idcourse, typeCourse) {
    return async (dispatch) => {
      const newAnswer = {
        answerstext: `Alternativa ${answersQty + 1}`,
        iscorrect: false,
        content_id: quizId
      };

      try {
        await API.post('answers', newAnswer, Config.headersJson());

        if (typeCourse === 'Longo') {
          dispatch(CourseApi.editCourseV2(idcourse));
        } else {
          dispatch(CourseApi.editCourse(idcourse));
        }

        dispatch({
          type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
          indexContent: 0
        });

        PubSub.publish('clear_new_elements', true);
        setTimeout(() => {
          PubSub.publish('move_scroll', true);
        }, 1000);
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
        dispatch({
          type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
          indexContent: 0
        });
      }
    };
  }

  static removeQuizAnswer(targetAnswer, idcourse, typeCourse) {
    return async (dispatch) => {
      try {
        let API_CLIENT = API;

        if (style.teamName.name === 'youseed') {
          API_CLIENT = APIDelete;
        }

        await API_CLIENT.delete(
          `answers/${targetAnswer}`,
          Config.headersJson()
        );

        if (typeCourse === 'Longo') {
          dispatch(CourseApi.editCourseV2(idcourse));
        } else {
          dispatch(CourseApi.editCourse(idcourse));
        }
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
        dispatch({
          type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
          indexContent: 0
        });
      }
    };
  }

  static saveanswer(values, idcontent, idcourse, typeCourse) {
    return async (dispatch) => {
      const { answer } = values;
      const quizAnswer = await this.isEmptyAnswer(answer);
      if (quizAnswer.isEmpty) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: 'Campo de resposta não pode ficar em branco.'
          }
        });
        dispatch({
          type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
          indexContent: 0
        });
        return;
      }

      try {
        for (const answer of values) {
          await API.post('answers', answer, Config.headersJson());
        }

        if (typeCourse === 'Longo') {
          dispatch(CourseApi.editCourseV2(idcourse));
        } else {
          dispatch(CourseApi.editCourse(idcourse));
        }
        dispatch({
          type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
          indexContent: 0
        });
        PubSub.publish('clear_new_elements', true);
        setTimeout(() => {
          PubSub.publish('move_scroll', true);
        }, 1000);
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
        dispatch(CourseApi.editCourseV2(idcourse));
      }
    };
  }

  static updatedAnswers(values, idcontent, idcourse, typeCourse, indexAnswers) {
    return async (dispatch) => {
      const { answer } = values;
      const quizAnswer = await this.isEmptyAnswer(answer);
      if (quizAnswer.isEmpty) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: 'Campo de resposta não pode ficar em branco.'
          }
        });
        dispatch({
          type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
          indexContent: 0
        });
        return;
      }

      try {
        const updatedAnswer = values[indexAnswers];
        API.patch(
          `answers/${updatedAnswer.id}`,
          updatedAnswer,
          Config.headersJson()
        );
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
        dispatch(CourseApi.editCourseV2(idcourse));
      }

      if (typeCourse === 'Longo') {
        dispatch(CourseApi.editCourseV2(idcourse));
      } else {
        dispatch(CourseApi.editCourse(idcourse));
      }
      dispatch({
        type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
        indexContent: 0
      });
      PubSub.publish('clear_new_elements', true);
      setTimeout(() => {
        PubSub.publish('move_scroll', true);
      }, 1000);
    };
  }
}
